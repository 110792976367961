var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b({ error: _vm.isShakeError && _vm.hasError, simple: _vm.isSimple, group: _vm.group })},[(!!_vm.label)?_c('label',{class:_vm.b('label')},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.b('block', _vm.classWrap)},[_c('v-select',{staticClass:"BaseSelect__select",class:{
        'BaseSelect__select--right': _vm.isDropdownRight,
        'BaseSelect__actions--no': !_vm.isArrow,
        'BaseSelect__select--small': _vm.isSmall,
        'BaseSelect__select--error': _vm.hasError,
      },attrs:{"multiple":_vm.multiple,"options":_vm.options,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"label":_vm.labelName,"reduce":_vm.reduce,"searchable":_vm.searchable},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
      var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('svg',{attrs:{"width":"11","height":"10","viewBox":"0 0 11 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.5293 4.11731L5.52234 8.12427L1.51538 4.11731","stroke":"#16192C","stroke-width":"1.5","stroke-linecap":"round"}})])])]}}]),model:{value:(_vm.initValue),callback:function ($$v) {_vm.initValue=$$v},expression:"initValue"}}),(_vm.hasError)?_c('div',{staticClass:"Field__error"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }